<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Powiat">
          <b-form-input v-model="form.district" />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <vue-autocomplete
          class="auto-input"
          label="Kod pocztowy"
          v-model="form.postalCode"
          :value="form.postalCode"
          :values="postalCodesItems"

          :maxResults="10"
        ></vue-autocomplete>
      </div>
      <div class="col-8">
        <vue-autocomplete
          class="auto-input"
          label="Miasto"
          v-model="form.city"
          :value="form.city"
          :values="citiesItems"

          :maxResults="50"
        ></vue-autocomplete>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <vue-autocomplete
          class="auto-input"
          label="Ulica"
          v-model="form.street"
          :value="form.street"
          :values="streetsItems"

          :maxResults="50"
        ></vue-autocomplete>
      </div>
      <div class="col-4">
        <b-form-group label="Numer domu" label-for="houseNumber">
          <b-form-input
            id="houseNumber"
            v-model="form.houseNumber"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  POSTALCODES_SEARCH,
  CLEAR_POSTALCODES
} from "@/core/services/store/postalCode.module";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "FermAddressAutocomplete",
  props: ["form", "section"],
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters(["postalCodes"]),
    postalCodesItems() {
      let result = [];
      _.forEach(this.postalCodes[this.section], postalCode => {
        result.push(postalCode.postcode);
      });
      return result;
    },
    citiesItems() {
      let result = [];
      _.forEach(this.postalCodes[this.section], postalCode => {
        _.forEach(postalCode.cities, city => {
          result.push(city.city);
        });
      });
      return result;
    },
    streetsItems() {
      let result = [];
      _.forEach(this.postalCodes[this.section], postalCode => {
        _.forEach(postalCode.cities, city => {
          if (this.form.city === city.city) {
            result = city.streets;
          }
        });
      });
      return result;
    }
  },
  methods: {
    checkSearchPostalCode: _.debounce(function(newVal) {
      this.loading = true;
      if (newVal.length >= 2) {
        this.$store.dispatch(POSTALCODES_SEARCH, {
          postcode: newVal,
          section: this.section
        });
        this.loading = false;
      } else {
        this.$store.dispatch(CLEAR_POSTALCODES, this.section);
      }
    }, 200)
  },
  watch: {
    // "v.postalCode.$model": function(newVal) {
    //   this.checkSearchPostalCode(newVal);
    // }
  }
};
</script>

<style scoped></style>

<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="modal-nip-search-confirm"
      ref="modal"
      size="lg"
      title="Nanoszenie zmian"
      @cancel="handleResetFoundClient"
      @ok="handleFillFoundClient"
      :ok-disabled="isFillingDataSameAsCurrent"
      ok-title="Nanieś zmiany"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <template v-if="!isFillingDataSameAsCurrent">
        <h5>Naniesione zostaną dane Klienta:</h5>
        <table class="table">
          <thead>
            <tr>
              <th>Parametr</th>
              <th>Stara wartość</th>
              <th>Nowa wartość</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nazwa firmy</td>
              <td>{{ form.address.name ? form.address.name : "-" }}</td>
              <td>
                {{ foundClient.companyName ? foundClient.companyName : "-" }}
              </td>
            </tr>
            <tr>
              <td>Miejscowość</td>
              <td>{{ form.address.city ? form.address.city : "-" }}</td>
              <td>
                {{ foundClient.address.city ? foundClient.address.city : "-" }}
              </td>
            </tr>
            <tr>
              <td>Kod pocztowy</td>
              <td>
                {{ form.address.postalCode ? form.address.postalCode : "-" }}
              </td>
              <td>
                {{
                  foundClient.address.postalCode
                    ? foundClient.address.postalCode
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <td>Ulica</td>
              <td>{{ form.address.street ? form.address.street : "-" }}</td>
              <td>
                {{
                  foundClient.address.street ? foundClient.address.street : "-"
                }}
              </td>
            </tr>
            <tr>
              <td>Numer domu</td>
              <td>
                {{ form.address.houseNumber ? form.address.houseNumber : "-" }}
              </td>
              <td>
                {{
                  foundClient.address.houseNumber
                    ? foundClient.address.houseNumber
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <td>NIP</td>
              <td>{{ form.nip ? form.nip : "-" }}</td>
              <td>{{ foundClient.nip ? foundClient.nip : "-" }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{{ form.sales_status.join(", ") ? form.sales_status : "-" }}</td>
              <td>{{ foundClient.status ? foundClient.status : "-" }}</td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>{{ form.sales_email1 ? form.sales_email1 : "-" }}</td>
              <td>{{ foundClient.email ? foundClient.email : "-" }}</td>
            </tr>
            <tr>
              <td>Numer telefonu</td>
              <td>{{ form.sales_phone1 ? form.sales_phone1 : "-" }}</td>
              <td>{{ foundClient.phone ? foundClient.phone : "-" }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        Nie znaleziono zmian
      </template>
    </b-modal>
  </div>
</template>

<script>
import { CLEAR_FOUND_CLIENT } from "@/core/services/store/client.module";
import { mapGetters } from "vuex";

export default {
  name: "ClientNipSearchConfirmModal",
  props: ["form"],
  data() {
    return {
      submittedNames: []
    };
  },
  methods: {
    handleFillFoundClient() {
      this.form.address.name = this.foundClient.companyName;
      this.form.address.city = this.foundClient.address.city;
      this.form.address.postalCode = this.foundClient.address.postalCode;
      this.form.address.street = this.foundClient.address.street;
      this.form.address.houseNumber = this.foundClient.address.houseNumber;
      this.form.address.apartmentNumber = this.foundClient.address.apartmentNumber;
      this.form.nip = this.foundClient.nip;
      switch (this.foundClient.status) {
        case "Aktywny":
          this.form.sales_status = ["active"];
          break;
        case "Nieaktywny":
          this.form.sales_status = ["inactive"];
          break;
        default:
          this.form.sales_status = [];
      }
      this.form.lab_status = [];
      this.form.sales_email1 = this.foundClient.email;
      this.form.sales_phone1 = this.foundClient.phone;
    },
    handleResetFoundClient() {
      this.$store.dispatch(CLEAR_FOUND_CLIENT);
    }
  },
  computed: {
    ...mapGetters(["foundClient"]),
    isFillingDataSameAsCurrent() {
      return (
        this.form.address.name === this.foundClient.companyName &&
        this.form.address.city === this.foundClient.address.city &&
        this.form.address.postalCode === this.foundClient.address.postalCode &&
        this.form.address.street === this.foundClient.address.street &&
        this.form.address.houseNumber ===
          this.foundClient.address.houseNumber &&
        this.form.sales_email1 === this.foundClient.email &&
        this.form.sales_phone1 === this.foundClient.phone
      );
    }
  }
};
</script>

<style scoped></style>

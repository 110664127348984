<template>
  <b-form-group label="Osoba obsługująca" label-for="attendant">
    <b-form-select
      multiple
      id="attendant"
      v-model="attendantsLocal"
      :options="attendantsList"
    >
    </b-form-select>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_LIST } from "@/core/services/store/user.module";

export default {
  name: "ChooseAttendants",
  props: ["attendants"],
  model: {
    prop: "attendants",
    event: "attendantsChange"
  },
  mounted() {
    this.$store.dispatch(USER_LIST);
  },
  computed: {
    ...mapGetters(["users"]),
    attendantsList() {
      let result = [
        {
          value: -1,
          text: "Brak"
        }
      ];
      this.users.forEach(user => {
        result.push({
          value: user.id,
          text: user.firstName + " " + user.lastName
        });
      });
      return result;
    },
    attendantsLocal: {
      get: function() {
        return this.attendants;
      },
      set: function(value) {
        this.$emit("attendantsChange", value);
      }
    }
  }
};
</script>

<style scoped></style>

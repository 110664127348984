<template>
  <b-form novalidate>
    <b-tabs content-class="mt-3">
      <b-tab title="RODO">
        <div class="row">
          <div class="col-xl-12">
            <b-form-group class="mt-10" label-for="agreementsAll">
              <b-form-checkbox id="agreementsAll" v-model="form.agreementsAll">
                Zaznacz wszystkie
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="mt-10" label-for="agreement1">
              <b-form-checkbox id="agreement1" v-model="form.agreement1">
                TAK, zgadzam się na przetwarzanie moich danych osobowych
                podanych w formularzu przez Centrum Naukowo Badawcze LADROB
                Łukasz Latała z siedzibą w Opolu, przy ul. Północnej 10, NIP:
                7542561371 jako administratora danych osobowych, w celach
                marketingowych, zgodnie z bezwzględnie obowiązującymi przepisami
                prawa. Zostałem poinformowany o tym, że podanie ww. danych jest
                dobrowolne oraz że mam prawo do dostępu do swoich danych, ich
                poprawiania, a także wycofania udzielonej zgody w dowolnym
                momencie, a także o pozostałych kwestiach wynikających z art. 13
                RODO, dostępnych w Polityce prywatności Centrum Naukowo Badawcze
                LADROB Łukasz Latała z siedzibą w Opolu, przy ul. Północnej 10,
                NIP: 7542561371.
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="mt-10" label-for="agreement2">
              <b-form-checkbox id="agreement2" v-model="form.agreement2">
                TAK, chcę być informowany e-mailowo o wydarzeniach, promocjach i
                ofertach specjalnych przez Centrum Naukowo Badawcze LADROB
                Łukasz Latała z siedzibą w Opolu, przy ul. Północnej 10, NIP:
                7542561371 i w związku z tym zgadzam się na otrzymywanie
                informacji handlowych wysyłanych przez Centrum Naukowo Badawcze
                LADROB Łukasz Latała z siedzibą w Opolu, przy ul. Północnej 10,
                NIP: 7542561371 na wyżej podany adres e-mail. Zostałem
                poinformowany o tym, że mogę wycofać tak udzieloną zgodę w
                dowolnym momencie, a także o pozostałych kwestiach wynikających
                z art. 13 RODO, dostępnych w Polityce prywatności Centrum
                Naukowo Badawcze LADROB Łukasz Latała z siedzibą w Opolu, przy
                ul. Północnej 10, NIP: 7542561371.
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="mt-10" label-for="agreement3">
              <b-form-checkbox id="agreement3" v-model="form.agreement3">
                TAK, chcę być informowany telefonicznie o promocjach i ofertach
                specjalnych Centrum Naukowo Badawcze LADROB Łukasz Latała z
                siedzibą w Opolu, przy ul. Północnej 10, NIP: 7542561371 i w
                związku z tym zgadzam się na otrzymywanie telefonicznych
                połączeń przychodzących inicjowanych przez Centrum Naukowo
                Badawcze LADROB Łukasz Latała z siedzibą w Opolu, przy ul.
                Północnej 10, NIP: 7542561371 na wyżej podany numer telefonu w
                celach handlowych i marketingowych. Zostałem poinformowany o
                tym, że mogę wycofać tak udzieloną zgodę w dowolnym momencie, a
                także o pozostałych kwestiach wynikających z art. 13 RODO,
                dostępnych w Polityce prywatności Centrum Naukowo Badawcze
                LADROB Łukasz Latała z siedzibą w Opolu, przy ul. Północnej 10,
                NIP: 7542561371.
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </b-tab>
      <b-tab title="Dane podstawowe" active>
        <div class="row">
          <div class="col-xl-6">
            <NipSearchForm :form="form" />
            <b-form-group
              label="Nazwa firmy lub Imię i Nazwisko klienta"
              label-for="name"
              invalid-feedback="To pole jest wymagane"
              :state="validateField('name', 'address')"
            >
              <b-form-input
                id="name"
                v-model="v.form.address.name.$model"
              ></b-form-input>
            </b-form-group>
            <AddressAutocomplete
              :v="v.form.address"
              :form="form.address"
              section="address"
            />
          </div>
          <div class="col-xl-6">
            <b-form-group label-for="isVat">
              <label>&nbsp;</label>
              <b-form-checkbox id="isVat" v-model="form.isVat"
                >Płatnik VAT</b-form-checkbox
              >
            </b-form-group>
            <b-form-group label="Kolor" label-for="color">
              <b-form-select
                id="color"
                v-model="form.color"
                :options="colorList"
              >
              </b-form-select>
            </b-form-group>

            <b-form-group class="mt-10" label-for="isDeliveryAddress1Enabled">
              <b-form-checkbox
                id="isDeliveryAddress1Enabled"
                v-model="handleToggleDeliveryAddress"
                >Adres 2</b-form-checkbox
              >
            </b-form-group>
            <div
              v-if="state.isDeliveryAddress1Enabled"
              class="deliveryAddresFieldset"
            >
              <b-form-group
                label="Nazwa"
                label-for="deliveryName"
                invalid-feedback="To pole jest wymagane"
                :state="validateField('name', 'deliveryAddress1')"
              >
                <b-form-input
                  id="deliveryName"
                  v-model="v.form.deliveryAddress1.name.$model"
                ></b-form-input>
              </b-form-group>

              <AddressAutocomplete
                :v="v.form.deliveryAddress1"
                :form="form.deliveryAddress1"
                section="deliveryAddress1"
              />
            </div>
            <b-form-group class="mt-10" label-for="isDeliveryAddress2Enabled">
              <b-form-checkbox
                id="isDeliveryAddress2Enabled"
                v-model="handleToggleDeliveryAddress2"
                >Adres 3</b-form-checkbox
              >
            </b-form-group>
            <div
              v-if="state.isDeliveryAddress2Enabled"
              class="deliveryAddresFieldset"
            >
              <b-form-group
                label="Nazwa"
                label-for="deliveryName2"
                invalid-feedback="To pole jest wymagane"
                :state="validateField('name', 'deliveryAddress2')"
              >
                <b-form-input
                  id="deliveryName2"
                  v-model="v.form.deliveryAddress2.name.$model"
                ></b-form-input>
              </b-form-group>

              <AddressAutocomplete
                :v="v.form.deliveryAddress2"
                :form="form.deliveryAddress2"
                section="deliveryAddress2"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Dane sprzedażowe">
        <div class="row">
          <div class="col-xl-4">
            <b-form-group label="Status" label-for="sales_status">
              <b-form-select
                id="sales_status"
                v-model="form.sales_status"
                multiple
                :options="statusList"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group label="Rodzaj hodowli" label-for="sales_types">
              <b-form-select
                id="sales_types"
                v-model="form.sales_types"
                multiple
                :options="typesList"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group label-for="isInvoiceEmail">
              <label>&nbsp;</label>
              <b-form-checkbox id="isInvoiceEmail" v-model="form.isInvoiceEmail"
                >Wysyłka faktur mailem</b-form-checkbox
              >
            </b-form-group>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-4">
            <b-form-group label="Wielkość (w tys.)" label-for="sales_size">
              <b-form-input
                type="number"
                min="0"
                step="1"
                id="sales_size"
                v-model="form.sales_size"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group
              label="Wysokość rabatu"
              label-for="sales_discountValue"
            >
              <b-form-input
                type="number"
                min="0"
                step="0.01"
                id="sales_discountValue"
                v-model="form.sales_discountValue"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <ChooseAttendant
              @attendantchange="attendantChanged('sales', $event)"
              :attendant="form.sales_attendant"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-4">
            <b-form-group label="Email 1" label-for="sales_email1">
              <b-form-input
                id="sales_email1"
                v-model="form.sales_email1"
                @keyup="emailRemovePolish('sales_email1')"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group label="Email 2" label-for="sales_email2">
              <b-form-input
                id="sales_email2"
                v-model="form.sales_email2"
                @keyup="emailRemovePolish('sales_email2')"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group label="Email 3" label-for="sales_email3">
              <b-form-input
                id="sales_email3"
                v-model="form.sales_email3"
                @keyup="emailRemovePolish('sales_email3')"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-xl-5">
            <b-form-group label="Numer telefonu 1" label-for="sales_phone1">
              <b-form-input
                id="sales_phone1"
                v-model="form.sales_phone1"
                placeholder="Numer telefonu 1"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-7">
            <b-form-group
              label="Imię i nazwisko osoby do kontaktu 1"
              label-for="sales_contactPersonFullName1"
            >
              <b-form-input
                id="sales_contactPersonFullName1"
                v-model="form.sales_contactPersonFullName1"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-xl-5">
            <b-form-group label="Numer telefonu 2" label-for="sales_phone2">
              <b-form-input
                id="sales_phone2"
                v-model="form.sales_phone2"
                placeholder="Numer telefonu 2"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-7">
            <b-form-group
              label="Imię i nazwisko osoby do kontaktu 2"
              label-for="sales_contactPersonFullName2"
            >
              <b-form-input
                id="sales_contactPersonFullName2"
                v-model="form.sales_contactPersonFullName2"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-5">
            <b-form-group label="Numer telefonu 3" label-for="sales_phone3">
              <b-form-input
                id="sales_phone3"
                v-model="form.sales_phone3"
                placeholder="Numer telefonu 3"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-7">
            <b-form-group
              label="Imię i nazwisko osoby do kontaktu 3"
              label-for="sales_contactPersonFullName3"
            >
              <b-form-input
                id="sales_contactPersonFullName3"
                v-model="form.sales_contactPersonFullName3"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <b-form-group class="mt-10" label-for="isDeliveryAddress1Enabled">
              <b-form-checkbox
                id="isDeliveryAddress1Enabled"
                v-model="handleToggleDeliveryAddress"
                >Adres 2</b-form-checkbox
              >
            </b-form-group>
            <div
              v-if="state.isDeliveryAddress1Enabled"
              class="deliveryAddresFieldset"
            >
              <b-form-group
                label="Nazwa"
                label-for="deliveryName"
                invalid-feedback="To pole jest wymagane"
                :state="validateField('name', 'deliveryAddress1')"
              >
                <b-form-input
                  id="deliveryName"
                  v-model="v.form.deliveryAddress1.name.$model"
                ></b-form-input>
              </b-form-group>

              <AddressAutocomplete
                :v="v.form.deliveryAddress1"
                :form="form.deliveryAddress1"
                section="deliveryAddress1"
              />
            </div>
          </div>
          <div class="col-6">
            <b-form-group class="mt-10" label-for="isDeliveryAddress2Enabled">
              <b-form-checkbox
                id="isDeliveryAddress2Enabled"
                v-model="handleToggleDeliveryAddress2"
                >Adres 3</b-form-checkbox
              >
            </b-form-group>
            <div
              v-if="state.isDeliveryAddress2Enabled"
              class="deliveryAddresFieldset"
            >
              <b-form-group
                label="Nazwa"
                label-for="deliveryName2"
                invalid-feedback="To pole jest wymagane"
                :state="validateField('name', 'deliveryAddress2')"
              >
                <b-form-input
                  id="deliveryName2"
                  v-model="v.form.deliveryAddress2.name.$model"
                ></b-form-input>
              </b-form-group>

              <AddressAutocomplete
                :v="v.form.deliveryAddress2"
                :form="form.deliveryAddress2"
                section="deliveryAddress2"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Dane laboratorium">
        <div class="row">
          <div class="col-xl-3">
            <b-form-group label="Status" label-for="lab_status">
              <b-form-select
                id="status"
                v-model="form.lab_status"
                multiple
                :options="labStatusList"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="col-xl-3">
            <b-form-group label="Matryca" label-for="lab_matrix">
              <b-form-select
                id="lab_matrix"
                v-model="form.lab_matrix"
                multiple
                :options="matrixList"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="col-xl-3">
            <b-form-group label="Wysokość rabatu" label-for="lab_discountValue">
              <b-form-input
                type="number"
                min="0"
                step="0.01"
                id="lab_discountValue"
                v-model="form.lab_discountValue"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-3">
            <ChooseAttendant
              @attendantchange="attendantChanged('lab', $event)"
              :attendant="form.lab_attendant"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-4">
            <b-form-group
              label="Email do wysyłki wyników"
              label-for="lab_email1"
            >
              <b-form-input
                id="lab_email1"
                v-model="form.lab_email1"
                @keyup="emailRemovePolish('lab_email1')"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group
              label="Email do wysyłki faktur"
              label-for="lab_email2"
            >
              <b-form-input
                id="lab_email2"
                v-model="form.lab_email2"
                @keyup="emailRemovePolish('lab_email2')"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group label="Email do promocji" label-for="lab_email3">
              <b-form-input
                id="lab_email3"
                v-model="form.lab_email3"
                @keyup="emailRemovePolish('lab_email3')"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-4">
            <b-form-group label="Numer telefonu 1" label-for="lab_phone1">
              <b-form-input
                id="lab_phone1"
                v-model="form.lab_phone1"
                placeholder="Numer telefonu 1"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group label="Numer telefonu 2" label-for="lab_phone2">
              <b-form-input
                id="lab_phone2"
                v-model="form.lab_phone2"
                placeholder="Numer telefonu 2"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group label="Numer telefonu 3" label-for="lab_phone3">
              <b-form-input
                id="lab_phone3"
                v-model="form.lab_phone3"
                placeholder="Numer telefonu 3"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-4">
            <b-form-group
              label="Imię i nazwisko osoby do kontaktu 1"
              label-for="lab_contactPersonFullName1"
            >
              <b-form-input
                id="lab_contactPersonFullName1"
                v-model="form.lab_contactPersonFullName1"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group
              label="Imię i nazwisko osoby do kontaktu 2"
              label-for="lab_contactPersonFullName2"
            >
              <b-form-input
                id="lab_contactPersonFullName2"
                v-model="form.lab_contactPersonFullName2"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group
              label="Imię i nazwisko osoby do kontaktu 3"
              label-for="lab_contactPersonFullName3"
            >
              <b-form-input
                id="lab_contactPersonFullName3"
                v-model="form.lab_contactPersonFullName3"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-4">
            <b-form-group
              label="Rola osoby 1"
              label-for="lab_contactPersonRole1"
            >
              <b-form-input
                id="lab_contactPersonFullName1"
                v-model="form.lab_contactPersonRole1"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group
              label="Rola osoby 2"
              label-for="lab_contactPersonRole2"
            >
              <b-form-input
                id="lab_contactPersonFullName2"
                v-model="form.lab_contactPersonRole2"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-xl-4">
            <b-form-group
              label="Rola osoby 3"
              label-for="lab_contactPersonRole3"
            >
              <b-form-input
                id="lab_contactPersonFullName3"
                v-model="form.lab_contactPersonRole3"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </b-tab>
      <b-tab title="Adresy ferm" v-if="isFerms">
        <div class="row">
          <a class="btn btn-icon btn-light btn-sm" @click="ferm(client)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="media/svg/icons/Code/Plus.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
            <FermAddModal :client="client" :type="'standard'" />
          </a>
        </div>

        <div class="row mt-3" v-for="(ferm, i) in fermsToShow" :key="i">
          <div class="col-12 p-0">
            <b-card>
              Nazwa: {{ ferm.name }}<br />
              {{ ferm.city }} {{ ferm.postalCode }}, {{ ferm.street }}
              {{ ferm.houseNumber }}<br />
              Rodzaj hodowli: {{ ferm.breedingType }}<br />
              Miejsce pobrania próbki {{ ferm.samplingPlace }}<br />
              WNI: {{ ferm.wni }}<br />
              Adres PIW: {{ ferm.vetInspEmail }}<br>
              <b-button
                @click="deleteFerm(ferm.id)"
                variant="primary"
                class="p-2 m-2"
                >Usuń</b-button
              >
              <b-button 
                disabled
                variant="primary" class="p-2 m-2"
                >Edycja</b-button
              >
            </b-card>
          </div>

          <hr />
        </div>
      </b-tab>
      <b-tab title="Dodatkowe">
        <div class="row">
          <div class="col-xl-3">
            <b-form-group label="Notatka dla zamówień">
              <b-form-textarea
                v-model="form.extra_note"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </b-tab>
      <b-tab title="Płatnik">
        <div class="row">
          <div class="col-xl-6">
            <b-form-group label="NIP">
              <b-form-input v-model="form.payer.nip" />
            </b-form-group>

            <b-form-group label="Nazwa lub imię i nazwisko płatnika">
              <b-form-input v-model="form.payer.name" />
            </b-form-group>

            <div class="row">
              <div class="col-xl-4">
                <b-form-group label="Kod pocztowy">
                  <b-form-input v-model="form.payer.zip" />
                </b-form-group>
              </div>

              <div class="col-xl-8">
                <b-form-group label="Miasto">
                  <b-form-input v-model="form.payer.city" />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-8">
                <b-form-group label="Ulica">
                  <b-form-input v-model="form.payer.street" />
                </b-form-group>
              </div>

              <div class="col-xl-4">
                <b-form-group label="Numer Domu">
                  <b-form-input v-model="form.payer.houseNumber" />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-form>
</template>

<script>
import NipSearchForm from "../../form/client/ClientNipSearchForm";
import ChooseAttendant from "../../form/client/ChooseAttendant";
import AddressAutocomplete from "../../form/client/AddressAutocomplete";
import FermAddModal from "@/view/content/modal/FermAddModal";
import _ from "lodash";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { FERM_SOFT_DELETE } from "@/core/services/store/ferm.module";

export default {
  name: "ClientAddEditForm",
  components: {
    NipSearchForm,
    ChooseAttendant,
    AddressAutocomplete,
    FermAddModal,
  },
  props: ["form", "v", "state", "client"],
  data() {
    return {
      colorList: [
        { value: "grey", text: "Szary", bg: "#AAAAAA" },
        { value: "green", text: "Zielony", bg: "#00DD00" },
        { value: "blue", text: "Niebieski", bg: "#006bdd" },
        { value: "red", text: "Czerwony", bg: "#dd0000" },
        { value: "orange", text: "Pomarańczowy", bg: "#dd6f00" },
        { value: "purple", text: "Fioletowy", bg: "#8c00dd" },
      ],
      statusList: [
        { value: "active", text: "Aktywny" },
        { value: "inactive", text: "Nieaktywny" },
        { value: "laboratory", text: "Laboratorium" },
        { value: "progres", text: "Progres" },
        { value: "end_of_breeding", text: "Koniec hodowli" },
        { value: "blacklisted", text: "Czarna lista" },
      ],
      labStatusList: [
        { value: "lab-active", text: "Laboratorium Aktywny" },
        { value: "lab-inactive", text: "Nieaktywny" },
        { value: "lab-progres", text: "Progres" },
      ],
      typesList: [
        { value: "gesi", text: "Gęsi" },
        { value: "kaczki", text: "Kaczki" },
        { value: "indyki", text: "Indyki" },
        { value: "nioski reprodukcyjne", text: "Nioski reprodukcyjne" },
        { value: "nioski towarowe", text: "Nioski towarowe" },
        { value: "brojlery", text: "Brojlery" },
        { value: "golebie", text: "Gołębie" },
        { value: "norki", text: "Norki" },
        { value: "odchowka", text: "Odchówka" },
        { value: "kroliki", text: "Króliki" },
        { value: "inne", text: "Inne" },
      ],
      matrixList: [
        { value: "urzedowe", text: "Urzedowe" },
        { value: "weterynaria", text: "Weterynaria" },
        { value: "serologia", text: "Serologia" },
        { value: "pasza", text: "Pasza" },
        { value: "zywnosc", text: "Zywnosc" },
        { value: "woda", text: "Woda" },
        { value: "srodowisko", text: "Środowisko" },
      ],
    };
  },
  methods: {
    attendantChanged(attType, value) {
      if (attType === "lab") {
        this.form.lab_attendant = value;
      } else {
        this.form.sales_attendant = value;
      }
    },
    validateField(field, section = null) {
      if (section !== null) {
        const { $dirty, $error } = this.v.form[section][field];
        return $dirty ? !$error : null;
      } else {
        const { $dirty, $error } = this.v.form[field];
        return $dirty ? !$error : null;
      }
    },
    ferm(client) {
      this.$bvModal.show("ferm-add-modal-" + client.id);
    },
    handleDelete(id) {
      //console.log(id);
      this.$store.dispatch(FERM_SOFT_DELETE, { id: id }).then(() => {});
      this.$nextTick(() => {
        Swal.fire("Gotowe!", "Usuwanie udane.", "success");
      });
    },
    deleteFerm(fermId) {
      Swal.fire({
        title: "Usuwanie fermy",
        text: "Na pewno chcesz to zrobić?",
        icon: "warning",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleDelete(fermId);
          Swal.fire("Gotowe!", "Usunięto.", "success");
        }
      });
    },
    emailRemovePolish(key) {
      this.form[key]= this.form[key].replace(/ą|ę|ć|ź|ż|ó|ł|ń|ś/gi, function(x) {
          let r = {"ą": "a","ć": "c","ę": "e","ł": "l","ó": 'o',"ń": "n","ś": "s","ź": "z","ż": "z"};
          return r[x];
      })
    }
  },
  computed: {
    ...mapGetters(["ferms"]),
    isFerms() {
      return typeof this.client !== "undefined";
    },
    fermsToShow() {
      if (typeof this.client === "undefined") {
        return [];
      }
      return _.filter(this.ferms, (f) => {
        return f.client.id == this.client.id;
      });
    },
    handleChangeAttendant: {
      get() {
        return this.form.attendant;
      },
      set(newVal) {
        this.form.attendant = newVal;
      },
    },
    handleToggleDeliveryAddress: {
      get() {
        return this.state.isDeliveryAddress1Enabled;
      },
      set(newVal) {
        if (!newVal) {
          if (
            this.form.address.name === "" &&
            this.form.address.city === "" &&
            this.form.address.postalCode === "" &&
            this.form.address.street === "" &&
            this.form.address.houseNumber === ""
          ) {
            this.form.address.name = this.form.deliveryAddress1.name;
            this.form.address.city = this.form.deliveryAddress1.city;
            this.form.address.postalCode =
              this.form.deliveryAddress1.postalCode;
            this.form.address.street = this.form.deliveryAddress1.street;
            this.form.address.houseNumber =
              this.form.deliveryAddress1.houseNumber;
            this.form.address.apartmentNumber =
              this.form.deliveryAddress1.apartmentNumber;

            this.form.deliveryAddress1.name = "";
            this.form.deliveryAddress1.city = "";
            this.form.deliveryAddress1.postalCode = "";
            this.form.deliveryAddress1.street = "";
            this.form.deliveryAddress1.houseNumber = "";
          }
        }
        this.state.isDeliveryAddress1Enabled = newVal;
      },
    },
    handleToggleDeliveryAddress2: {
      get() {
        return this.state.isDeliveryAddress2Enabled;
      },
      set(newVal) {
        this.state.isDeliveryAddress2Enabled = newVal;
      },
    },
  },
  watch: {
    "form.agreementsAll"(newValue) {
      this.form.agreement1 = newValue;
      this.form.agreement2 = newValue;
      this.form.agreement3 = newValue;
    },
  },
};
</script>

<style scoped></style>

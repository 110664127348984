<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="'ferm-add-modal-' + client.id"
      ref="modal"
      :title="'Dodaj fermę dla: ' + client.name"
      size="lg"
      scrollable
      @show="initModal"
      @hide="resetForm"
      @cancel="resetForm"
      @ok="handleModalOk"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <b-form novalidate>
        <b-form-group
          :label="'Nazwa'"
          label-for="name"
          invalid-feedback="To pole jest wymagane"
        >
          <b-form-input
            id="name"
            placeholder="Podaj nazwę"
            v-model="form.fermAddress.name"
          ></b-form-input>
        </b-form-group>

        <FermAddressAutocomplete
          :form="form.fermAddress"
          section="fermAddress"
        />

        <b-form-group
          :label="'Miejsce pobrania próby'"
          label-for="samplingPlace"
        >
          <b-form-input
            id="samplingPlace"
            placeholder="Podaj miejsce"
            v-model="form.samplingPlace"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="'Rodzaj hodowli'"
          label-for="breedingType"
        >
          <b-form-select
            id="breedingType"
            v-model="form.breedingType"
            multiple
            :options="form.breedingList"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          :label="'Numer WNI'"
          label-for="WNI"
        >
          <b-form-input
            id="WNI"
            placeholder="Numer WNI"
            v-model="form.WNI"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email powiatowego ZHW">
          <b-form-input
            type="email"
            id="LHW"
            v-model="form.vetInspEmail"
            @keyup="emailRemovePolish('vetInspEmail')"
            invalid-feedback="To pole jest wymagane"
          />
        </b-form-group>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import { baseURL } from "@/core/services/api.service.js";
import { required } from "vuelidate/lib/validators";
import {
  FERM_ADD,
  FERM_SOFT_DELETE
} from "@/core/services/store/ferm.module";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import _ from "lodash";
import FermAddressAutocomplete from "../form/client/FermAddressAutocomplete";
// import moment from "moment";

export default {
  name: "ferm-add-modal",
  components: {
    FermAddressAutocomplete,
  },
  props: {
    client: Object,
    type: {
      type: String
    }
  },
  data() {
    return {
      form: {
        clientId: null,
        formState: null,
        fermAddress: {
          name: "",
          city: "",
          postalCode: "",
          street: "",
          houseNumber: "",
          apartmentNumber: "",
          district: ""
        },
        samplingPlace: "",
        WNI: "",
        vetInspEmail: "",
        breedingType: [],
        breedingList: [
          { value: "kury-reprodukcyjne", text: "Kury reprodukcyjne" },
          { value: "kury-nioski", text: "Kury nioski" },
          { value: "kury-brojlery", text: "Kury brojlery" },
          { value: "indyki-reprodukcyjne", text: "Indyki reprodukcyjne" },
          { value: "indyki-brojlery", text: "Indyki brojlery" },
          { value: "drob-nie-objety-kpzs", text: "Drób nie objęty KPZS" },
        ],
      },
      collapseNote: []
    };
  },
  validations: {
    form: {
      content: {
        required
      }
    }
  },
  methods: {
    emailRemovePolish(key) {
      this.form[key]= this.form[key].replace(/ą|ę|ć|ź|ż|ó|ł|ń|ś/gi, function(x) {
          let r = {"ą": "a","ć": "c","ę": "e","ł": "l","ó": 'o',"ń": "n","ś": "s","ź": "z","ż": "z"};
          return r[x];
      })
    },
    addFerm() {
      Swal.fire({
        title: "Dodawanie fermy?",
        text: "Na pewno chcesz to zrobić?",
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        // Add validation here
        if (result.isConfirmed) {
          if ( this.form.vetInspEmail.trim().length == 0 )
          {
            Swal.fire("Błąd!", "Pole [ Email powiatowego ZHW ] jest wymagane!", 'error');
          }
          else
          {
            this.handleSubmit();
            Swal.fire("Gotowe!", "Dodano.", "success");
          }
        }
      });
    },
    isNoteVisible(id) {
      return this.collapseNote.includes(id);
    },
    handleCollapseNote(id) {
      const exists = _.includes(this.collapseNote, id);
      if (!exists) {
        this.collapseNote.push(id);
      } else {
        const index = _.findIndex(this.collapseNote, id);
        let collapseSO = [...this.collapseNote];
        collapseSO.splice(index, 1);
        this.collapseNote = collapseSO;
      }
    },
    resetForm() {
      this.form.formState = null;
    },
    handleDelete(id) {
      //console.log(id);
      this.$store.dispatch(FERM_SOFT_DELETE, { id: id }).then(() => {});
      this.$nextTick(() => {
        Swal.fire("Gotowe!", "Usuwanie udane.", "success");
      });
    },
    handleSubmit() {
      this.$store.dispatch(FERM_ADD, this.form).then(() => {});
      this.$nextTick(() => {
        this.$bvModal.hide("ferm-add-modal-" + this.client.id);
      });
    },
    handleModalOk(event) {
      event.preventDefault();
      this.addFerm();
    },
    // validateField(field) {
    //   const { $dirty, $error } = this.$v.form[field];
    //   return $dirty ? !$error : null;
    // },
    // validateForm() {
    //   this.$v.form.$touch();
    //   this.form.formState = !this.$v.form.$invalid;
    // },
    initModal() {
      // this.$store.dispatch(NOTICE_FOR_CLIENT_LIST, {
      //   id: this.client.id
      // });
      // if (this.notices.length === 0) {
      //   this.$store.dispatch(NOTICE_LIST);
      // }
      if (typeof this.client !== "undefined") {
        this.form.clientId = this.client.id;
      }
    },
  },
  mounted() {
    this.initModal();
  },
  computed: {
    ...mapGetters(["ferms"]),
    getBaseURL() {
      return baseURL;
    },
    fermsToShow() {
      return _.filter(this.ferms, f => {
        return f.client.id == this.client.id;
      });
    },
  }
};
</script>

<template>
  <div>
    <ClientNipSearchConfirmModal v-if="foundClient" :form="form" />
    <b-form novalidate @submit.stop.prevent="handleSubmit">
      <b-form-group label="NIP" label-for="nip">
        <b-input-group>
          <b-form-input id="nip" v-model="form.nip"></b-form-input>
          <b-input-group-append>
            <b-button type="submit" :disabled="form.nip === ''"
              >Szukaj</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { CLIENT_SEARCH, SET_ERROR } from "@/core/services/store/client.module";
import { mapGetters } from "vuex";
import ClientNipSearchConfirmModal from "../../modal/ClientNipSearchConfirmModal";
import Swal from "sweetalert2";

export default {
  name: "ClientNipSearchForm",
  props: ["form"],
  components: { ClientNipSearchConfirmModal },
  data() {
    return {
      submittedNames: []
    };
  },
  methods: {
    async handleSubmit() {
      await this.$store.dispatch(CLIENT_SEARCH, {
        nip: this.form.nip
      });
    }
  },
  watch: {
    searchingStatus(newValue) {
      if (newValue) {
        if (this.foundClient) {
          this.$nextTick(() => {
            this.$bvModal.show("modal-nip-search-confirm");
          });
        }
      }
    },
    clientErrors(newValue) {
      if (this.clientErrors) {
        Swal.fire("Błąd!", newValue, "error").then(() => {
          this.$store.dispatch(SET_ERROR, null);
        });
      }
    }
  },
  computed: {
    ...mapGetters(["searchingStatus", "foundClient", "clientErrors"])
  }
};
</script>

<style scoped></style>
